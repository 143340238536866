import { ListVerificationRequests } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllVerificationRequests = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllVerificationRequestsList: [],
    };
  },
  methods: {
    async GetAllVerificationRequestsMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListVerificationRequests, {
            input: {
              user_id: this.$store.getters.get_current_user_details.user_id,
            },
          })
        );
        this.GetAllVerificationRequestsList = JSON.parse(result.data.ListVerificationRequests).data.items;
        if (this.GetAllVerificationRequestsList.length == 0) {
          this.noDataText = "No Verfication Request Found !!!";
        }
        this.overlay = false;
      } catch (error) {
        this.GetAllVerificationRequestsList = [];
        this.noDataText = error.errors[0].message;
        this.overlay = false;
      }
    },
  },
};
