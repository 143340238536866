<template>
  <v-card class="elevation-0 mx-6 mt-4">
    <v-card-text class="display-2">Privacy Policy</v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >CONSENT</v-card-title
    >
    <v-card-text
      >By using the relievingletter.com web portal (hereafter referred to as the
      APP), you hereby consent to this Privacy Policy and agree to its terms.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >INFORMATION COLLECTED</v-card-title
    >
    <v-card-text
      >You may be able to submit content, such as text, images, and other
      materials ("User Content"), to the APP. By submitting User Content, you
      grant us a non-exclusive, transferable, sub-licensable, royalty-free,
      worldwide license to use, modify, reproduce, distribute, display, and
      perform other functions with the User Content, solely in connection with
      the functional requirement of the APP. the APP may contain proprietary
      information and materials, including trademarks, copyrights, and other
      intellectual property rights ("Our Content"). You may not use Our Content
      without our prior written consent, except for the purpose conforming to
      the functional requirement of the APP. The personal information that you
      are asked to provide, and the reasons why you are asked to provide it,
      will be made clear to you at the point we ask you to provide your personal
      information. When you register for an account, we may ask for your contact
      information, including items such as name, company name, address, email
      address, telephone number, and related details. The identity related
      information is stored securely with us and made available only to you or
      recipients of the documents you secure. As part of the APP functionality,
      you may be asked to upload documents onto the portal. These documents are
      stored by the APP in encrypted form and are accessible only to the
      intended users.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >USE OF THE INFORMATION</v-card-title
    >
    <v-card-text
      >The personal or organization related information is used to create user
      profiles that are used to electronically secure documents. The information
      is not used for any other purpose or shared with any third parties. Data
      pertaining to authentication transactions are stored on the blockchain in
      summary form for verifying the APP documents, which is the core purpose of
      the software. Summary data is also maintained in log files. Anonymized
      data may be used internally by the APP for analytics. The contents of any
      documents secured electronically on the APP are securely encrypted, and
      never made readable either by us or with any third party without your
      consent.</v-card-text
    >
    <v-card-title class="headline font-weight-bold grey--text"
      >COOKIES</v-card-title
    >
    <v-card-text
      >The APP uses cookies to enable the functionality of certain areas and
      make it easier for people visiting our website. By signing up to the APP
      Terms of Service and Privacy Policy, it is assumed that you automatically
      agree to the use of cookies. the APP follows a strict policy of not
      storing third party cookies.</v-card-text
    >
    <v-card-title class="headline font-weight-bold grey--text"
      >THIRD PARTY PRIVACY POLICY</v-card-title
    >
    <v-card-text
      >The APP does not sell users' data to any third party. We aim to comply
      with applicable Data Protection regulations in the operating jurisdiction.
    </v-card-text>
    <v-footer dark padless height="300px" class="mx-n7">
      <v-card
        flat
        tile
        class="white--text text-center elevation-0"
        color="transparent"
      >
        <v-card-text class="ml-10"
          >© 2023 SureCreds Pvt. Ltd. All rights reserved.</v-card-text
        >
      </v-card>
    </v-footer>
  </v-card>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style></style>
