<template>
  <div>
    <Overlay :overlay="overlay" :count="count" />
    <v-app-bar :content-class="true" app clipped-left color="white" elevation="1">
      <v-card height="50" width="145" class="elevation-0">
        <v-img src="@/assets/NewLogo.png"></v-img>
      </v-card>
      <v-spacer> </v-spacer>
      <a href="https://relievingletter.com/"><v-icon large color="green">mdi-arrow-left-circle</v-icon></a>
    </v-app-bar>
    <v-main>
      <v-card class="elevation-0 mx-10 mt-n2">
        <v-card-text class="py-0">
          <v-row no-gutters>
            <v-col v-if="results == false" cols="12" xs="12" sm="12" :md="results == false ? 6 : 6" align="center">
              <v-card class="BorderPrimary elevation-0" dense3 align="center" justify="center" :height="heightRight"
                @drop.prevent="onDrop($event)" @dragover.prevent="dragover = true" @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false" @click="handleUpload">
                <v-card-text style="padding-top: 220px">
                  <v-btn small tile icon :class="[dragover ? 'mt-2, mb-6' : 'mt-12']" class="mr-2 text-capitalize"
                    color="green">
                    <v-icon size="60">mdi-cloud-upload</v-icon>
                    <input ref="excel-upload-input" accept="application/pdf" class="excel-upload-input" type="file"
                      @change="handleClick" />
                  </v-btn>

                  <p v-if="uploadedFiles.length == 0" class="mt-4 font-weight-regular hidden-md-and-down"
                    style="color: black">
                    Drop your file here, or click to upload.
                  </p>
                  <p v-if="uploadedFiles.length == 0" class="mt-4 font-weight-regular hidden-md-and-up"
                    style="color: black">
                    click to upload
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="results == true" cols="12" xs="12" sm="12" md="6" align="center">
              <v-card v-if="responseStatus == 'CONFIRMED'" class="BorderGreen elevation-0" color="#ecfaec"
                :height="heightRight">
                <v-row class="d-flex justify-space-between">
                  <v-col cols="8" md="8" sm="8">
                    <v-card-title class="pt-3 pl-6">
                      <div class="largeFontSizePrimaryGreen">
                        Valid Relieving Letter
                      </div>
                    </v-card-title>
                  </v-col>
                  <v-col cols="2" md="2">
                    <v-btn small class="mt-3" dark color="green" @click.stop="
                      (stepVerifyCredentials = 1),
                      (error = false),
                      (dragover = false),
                      (uploadedFiles = []),
                      (results = false)
                      ">Close</v-btn>
                  </v-col>
                </v-row>
                <v-card-text align="left" class="">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Issued On</v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.issued_on"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Issued By Organization</v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.issued_at"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Issued By User Name</v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.issued_by_user_name"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Issued To Receipient
                      </v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.issued_to"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Employee Id </v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.emp_Id"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Worked From </v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.worked_from"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Worked Till </v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.worked_till"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Designation </v-list-item-title>
                      <v-list-item-subtitle v-text="credentials.designation"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Additional Comments</v-list-item-title>
                      <v-list-item-subtitle>{{
                        credentials.comments ? credentials.comments : "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-card-text><a @click="$router.push('/Login')">Sign in</a>/<a
                      @click="$router.push('/RegisterWithUs')">Sign Up</a>
                    for more details</v-card-text>
                  <u class="ml-4" style="cursor: pointer" @click="checkOnBlockChainMethod()">Click here to view the
                    blockchain record</u>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <v-card v-if="responseStatus == 'MINING'" class="BorderOrange elevation-0" color="#fff6e5"
                :height="heightRight">
                <v-card-title class="pt-3">
                  <div class="largeFontSizePrimaryOrange">
                    Writing To Blockchain
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <v-card color="#f6dfdf" v-if="responseStatus == 'ERROR'" :height="heightRight"
                class="elevation-0 BorderRed elevation-0">
                <v-row class="d-flex justify-space-between">
                  <v-col cols="8" md="8">
                    <div align="left" class="largeFontSizeRed py-4 pl-2">
                      Invalid Relieving Letter
                    </div>
                  </v-col>
                  <v-col cols="2" md="2">
                    <v-btn dark small class="mt-4" color="red"
                      @click.stop="removeFile(uploadedFiles[0].name)">Close</v-btn>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" :md="results == false ? 6 : 6" align="center" class="">
              <v-card class="mainImageCard elevation-0" align="center" v-if="results == false">
                <v-row class="d-flex justify-center">
                  <v-col cols="6" md="6" sm="6">

                   <v-img
                      class="hidden-md-and-down"
                      max-width="500px"
                      src="@/assets/VerifyCredentials.jpeg"
                    ></v-img>
                    
                    <!-- <v-img class="hidden-md-and-down" max-width="500px" src="@/assets/Verifed_RL.svg"></v-img>-->
                  </v-col> 
                </v-row>
              </v-card>
              <v-card class="elevation-0 mx-8 mt-12" align="center" v-if="results == false">
                <div class="xlargeFontSize green--text hidden-md-and-down">
                  Verify Relieving Letter
                </div>
              </v-card>
              <v-row v-if="results == true" class="BorderPrimary mt-0 mx-4">
                <!-- <div class="xlargeFontSize py-2 pl-2">Selected Document</div> -->
                <iframe :height="heightPDF" :src="pdfsrc" width="100%" frameborder="0"></iframe>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  name: "Upload",
  props: {
    dialogUpload: Boolean,
    countCreds: Number,
  },
  components: {
    Overlay,
  },
  data: () => ({
    text: "",
    data: "",
    count: 0,
    height: 0,
    pdfsrc: "",
    snackbar: false,
    dragover: false,
    uploadedFiles: [],
    multiple: false,
    overlay: false,
    SnackBarComponent: {},
    stepVerifyCredentials: 1,
    dialogViewCredentials: false,
    listCredentialsHeaders: [
      { text: "Issued To", value: "issued_to_email_id" },
      { text: "Issued On", value: "issued_on" },
      { text: "Issued At", value: "issued_at" },
      // { text: "Issued By", value: "issued_by_user_name" },
    ],
    error: false,
    results: false,
    Height: "400px",
    verifyInterval: 0,
    heightRight: 0,
    credentials: {
      issued_to: "",
      issued_on: "",
      issued_at: "",
      issued_by_user_name: "",
      additional_comments: "",
      emp_Id: "",
      worked_from: "",
      worked_till: "",
      designation: "",
    },
    responseStatus: "",
    errorStatus: "",
  }),
  watch: {
    countCreds() {
      this.results = false;
    },
    dialogUpload() {
      this.uploadedFiles = [];
    },
  },
  mounted() {
    this.height = window.innerHeight - 210;
    this.heightPDF = window.innerHeight - 175;
    this.heightRight = window.innerHeight - 170;
  },
  methods: {
    checkOnBlockChainMethod() {
      window.open(
        `https://viewblock.io/arweave/tx/${this.credentials.transaction_id}`
      );
    },
    dialogViewCredentialsEmit() {
      this.dialogViewCredentials = false;
    },
    async VerifyCredentials(data) {
      var self = this;
      self.count = 60;
      self.overlay = true;
      const path = "verifycredentials";
      const myInit = {
        body: {
          command: "verifyCredential",
          credential_hash: data,
        },
      };
      API.post("RESTAPI", path, myInit)
        .then((response) => {
          console.log(response);
          self.responseStatus = response.status;
          self.count = 80;
          self.overlay = false;
          self.results = true;
          if (!response.errorType) {
            if (self.responseStatus == "CONFIRMED") {
              if (!response.data.valid_till) {
                self.credentials = {
                  transaction_id: response.data.transaction_id,
                  issued_to: response.data.issued_to,
                  issued_on: response.data.issued_on,
                  issued_at: response.data.issued_at,
                  issued_by_user_name: response.data.issued_by_user_name,
                  certificate_Number: response.data.credential_number,
                  comments: response.data.comments,
                  emp_Id: response.data.employee_id,
                  worked_from: response.data.worked_from,
                  worked_till: response.data.worked_till,
                  designation: response.data.designation,
                };
                this.expiryDate = false;
              } else {
                if (response.data.valid_till > new Date().getTime()) {
                  self.credentials = {
                    transaction_id: response.data.transaction_id,
                    issued_to: response.data.issued_to,
                    issued_on: response.data.issued_on,
                    issued_at: response.data.issued_at,
                    valid_till: response.data.valid_till,
                    issued_by_user_name: response.data.issued_by_user_name,
                    certificate_Number: response.data.credential_number,
                    comments: response.data.comments,
                  };
                  this.expiryDate = true;
                } else {
                  self.credentials = {
                    transaction_id: response.data.transaction_id,
                    issued_to: response.data.issued_to,
                    issued_on: response.data.issued_on,
                    issued_at: response.data.issued_at,
                    valid_till: response.data.valid_till,
                    issued_by_user_name: response.data.issued_by_user_name,
                    certificate_Number: response.data.credential_number,
                    comments: response.data.comments,
                  };
                  self.responseStatus = "ERROR";
                  self.errorStatus = "EXPIRED";
                }
              }
            }
          } else {
            self.responseStatus = "ERROR";
            self.errorStatus = "ERROR";
          }
        })
        .catch((error) => {
          console.warn(error);
          self.count = 0;
          self.overlay = true;
          self.results = true;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    uploadFiles() {
      this.count = 0;
      this.overlay = true;
      var fileReader = new FileReader();
      this.count = 20;
      fileReader.readAsDataURL(this.uploadedFiles[0]);
      var self = this;
      fileReader.onload = async function (val) {
        self.count = 40;
        self.pdfsrc = val.target.result;
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        const crypto = require("crypto");
        let data = crypto.createHash("sha256").update(buf).digest("hex");
        self.VerifyCredentials(data);
      };
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.uploadedFiles = [];
      this.dragover = true;
      if (e.target.files.length == 1) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.uploadedFiles.push(e.target.files[i]);
        }
        this.uploadFiles();
      }
    },
    closeDialog() {
      this.uploadedFiles = [];
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      if (index > -1) this.uploadedFiles.splice(index, 1);
      this.dragover = false;
      this.error = false;
      this.results = false;
    },
    onDrop(e) {
      this.uploadedFiles = [];
      if (e.dataTransfer.files.length == 1) {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.uploadedFiles.push(e.dataTransfer.files[i]);
        }
        this.uploadFiles();
      }
    },
  },
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.mainImageCard {
  padding-top: 100px;
  align: center;
}
</style>
