<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />

    <div class="signInCard">
      <div>
        <!-- Mobile -->
        <v-card v-show="$vuetify.breakpoint.name == 'xs'" class="elevation">
          <v-card class="mx-5 py-6 elevation-0">
            <v-card height="50" width="145" class="elevation-0 ml-12">
              <v-img src="@/assets/NewLogo.png"></v-img>
            </v-card>
            <h1 class="" align="center" style="color: rgb(97, 97, 97)">
              Welcome Back!
            </h1>
            <h5
              class="grey--text font-weight-regular mt-3"
              v-show="showEmail == true"
            >
              Please enter your email Address
            </h5>
          </v-card>
          <v-form ref="formEmail">
            <v-text-field
              color="green"
              outlined
              dense
              label="Enter Email*"
              v-model="email"
              class="mx-4 mt-n3"
              v-show="showEmail"
              prepend-inner-icon="mdi-email"
              :rules="[
                (v) => !!v || 'Required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
              ]"
              @keydown.enter="validateFormMethodSendOtp()"
            ></v-text-field>
          </v-form>
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn
              color="green"
              class="mainbutton white--text"
              @click="validateFormMethodSendOtp()"
              style="font-size: 13px"
              v-show="showEmail"
              :loading="loadingEmail"
              width="100%"
              >Send OTP</v-btn
            >
          </v-card-actions>
          <v-card-text v-if="otpshow == true" class="font-weight-bold"
            >This OTP will go to
            {{ this.$store.getters.get_user_email }}</v-card-text
          >
          <v-form ref="formOtp">
            <v-otp-input
              v-model="otp"
              class="mx-6"
              v-show="otpshow"
              :rules="[(v) => !!v || 'Required']"
              @keydown.enter="validateFormMethodVerifyOtp()"
            ></v-otp-input>
          </v-form>
          <v-card-actions class="d-flex justify-end mx-3">
            <!-- <v-btn
              class="mainbutton my-4 white--text"
              @click="validateFormMethodVerifyOtp()"
              style="font-size: 13px"
              v-show="otpshow"
              :loading="loadingOtp"
              width="100%"
              >Verify</v-btn
            > -->
          </v-card-actions>
        </v-card>
        <!-- Laptop -->
        <v-card
          v-show="$vuetify.breakpoint.name != 'xs'"
          class="elevation-1"
          width="500px"
          height="370"
        >
          <v-row class="d-flex justify-end">
            <!-- <v-col cols="2" md="2">

              <a href="https://relievingletter.com/" v-show="showEmail == true" ><v-icon large color="green">mdi-arrow-left-circle</v-icon></a>
              <v-icon large color="green"  v-if="otpshow == true" >mdi-arrow-left-circle</v-icon>
            </v-col> -->
            <v-col cols="2" md="2">
              <a href="https://relievingletter.com/" v-show="showEmail == true"
                ><v-icon large color="green">mdi-arrow-left-circle</v-icon></a
              >
            </v-col>
            <v-col v-if="otpshow == true" cols="2" md="2">
              <v-icon
                large
                color="green"
                @click="
                  showEmail = true;
                  otpshow = false;
                  loadingEmail = false;
                "
                >mdi-arrow-left-circle</v-icon
              ></v-col
            >
          </v-row>
          <v-card class="mx-5 py-6 elevation-0">
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="4" sm="4">
                <v-img src="@/assets/NewLogo.png"></v-img>
              </v-col>
            </v-row>
            <h1 class="" align="center" style="color: rgb(97, 97, 97)">
              Welcome !
            </h1>
            <h5
              class="grey--text font-weight-regular mt-3"
              v-show="showEmail == true"
            >
              Please enter your email Address
            </h5>
          </v-card>
          <v-form ref="formEmail">
            <v-text-field
              outlined
              color="green"
              dense
              label="Enter Email*"
              v-model="email"
              class="mx-4 mt-n3"
              v-show="showEmail"
              prepend-inner-icon="mdi-email"
              :rules="[
                (v) => !!v || 'Required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
              ]"
              @keydown.enter.prevent="validateFormMethodSendOtp()"
            ></v-text-field>
          </v-form>
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn
              color="green"
              class="mainbutton white--text mb-7"
              @click="validateFormMethodSendOtp()"
              style="font-size: 13px"
              v-show="showEmail"
              :loading="loadingEmail"
              width="100%"
              >Send OTP</v-btn
            >
          </v-card-actions>

          <div v-if="otpshow == true">
            <v-card-text class="font-weight-bold"
              >This OTP will go to
              {{ this.$store.getters.get_user_email }}</v-card-text
            >

            <v-form ref="formOtp">
              <div class="ml-5 mr-5">
                <v-otp-input
                  color="green"
                  v-model="otp"
                  v-show="otpshow"
                  :rules="[(v) => !!v || 'Required']"
                  @finish="validateFormMethodVerifyOtp()"
                ></v-otp-input>
              </div>
              <div></div>
            </v-form>
          </div>

          <!-- <v-card-actions class="d-flex justify-end mx-3">
            <v-btn
              class="mainbutton my-4 white--text"
              @click="validateFormMethodVerifyOtp()"
              style="font-size: 13px"
              v-show="otpshow"
              :loading="loadingOtp"
              width="100%"
              >Verify</v-btn
            >
          </v-card-actions> -->
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
// import axios from "axios";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
export default {
  components: {
    Snackbar,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    email: "",
    userDetails: "",
    otp: "",
    showEmail: true,
    otpshow: false,
    loadingEmail: false,
    loadingOtp: false,
    step: 1,
    Login: {
      EmailID: "",
      Password: "",
    },
    isPwd: false,
    loading: false,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    isPwdNew: false,
    isPwdConfirm: false,
    SnackBarComponent: {},
    OTPEmail: "",
    ConfirmOTP: "",
    ConfirmNewPassword: "",
    ReenterNewPassword: "",
    isPwdConfirmCode: false,
    rememberme: false,
    renderComp: true,
    error_Type: "",
    errorMessage: "",
  }),
  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("currentuseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.currentuseremail;
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Login";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        default:
          return "Account created";
      }
    },
  },
  mounted() {
    this.CheckRememberMe();
  },
  methods: {
    validateFormMethodSendOtp() {
      if (this.$refs.formEmail.validate()) {
        if (
          this.email == "riyad@wrktop.com" ||
          this.email == "contactus@wrktop.com"
        ) {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "This user is registered on surecreds.com",
              timeout: 5000,
              Top: true,
            };
            this.renderComp = true;
          });
        } else {
          this.generate_otp();
        }
      } else {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Please enter your Email",
          };
          this.renderComp = true;
        });
      }
    },
    // OTP Validate
    async generate_otp() {
      this.loadingEmail = true;
      try {
        await Auth.signIn(this.email)
          .then((user) => {
            this.userDetails = user;
            this.showEmail = false;
            this.otpshow = true;
            this.$store.commit("SET_USEREMAIL", this.email);
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "OTP Sent Successfully",
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
          })
          .catch((err) => {
            console.log(err);
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: "Could not find your account in RelievingLetter",
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
            this.loadingEmail = false;
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 10000,
          Top: true,
        };
        this.loadingEmail = false;
      }
    },
    validateFormMethodVerifyOtp() {
      if (this.$refs.formOtp.validate()) {
        this.login_with_otp();
      } else {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Please enter your OTP",
          };
          this.renderComp = true;
        });
      }
    },
    async login_with_otp() {
      this.loadingOtp = true;
      this.overlay = true;
      try {
        await Auth.sendCustomChallengeAnswer(this.userDetails, this.otp);
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            console.log(user);
            this.GetCurrentUser();
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: "OTP Verified Successfully",
                timeout: 10000,
                Top: true,
              };
              this.renderComp = true;
            });
            this.ActivateMethod();
          })
          .catch((error) => {
            console.log(error);
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: "Invalid OTP",
                timeout: 5000,
                Top: true,
              };
              this.renderComp = true;
            });
            this.loadingOtp = false;
            this.overlay = false;
          });
      } catch (error) {
        if (error.message == "Incorrect username or password.") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Your OTP is still wrong",
              timeout: 5000,
              Top: true,
            };
            this.renderComp = true;
          });
          this.loadingOtp = false;
          console.log("OTP is not verified2");
        } else if (error.message == "Invalid session for the user.") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Invalid session for the user",
              timeout: 5000,
              Top: true,
            };
            this.renderComp = true;
          });
          this.loadingOtp = false;
        }
        console.log(error);
      }
    },
    // async getOrgDetailsFromEmailId() {
    //   let url =
    //     "https://pe5at1eac8.execute-api.ap-south-1.amazonaws.com/dev/getorganizationdetailsfromemailid";
    //   let result = await axios.post(url, {
    //     contact_email_id: this.$store.getters.get_user_email,
    //   });
    //   console.log(result);
    //   console.log(result.data.data.Items[0].pre_signup);
    //   if (result.data.data.Items[0].pre_signup) {
    //     this.$router.push("/LandingPage");
    //   } else {
    //     this.ActivateMethod();
    //   }
    // },
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },
    ConfirmOTPMethod() {
      Auth.forgotPasswordSubmit(
        this.OTPEmail,
        this.ConfirmOTP,
        this.ConfirmNewPassword
      )
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.step = 1;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        if (this.ReenterNewPassword == this.ConfirmNewPassword) {
          this.ConfirmOTPMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    SendOTPEmail() {
      Auth.forgotPassword(this.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.step = 4;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(
          this.user,
          this.Password.ConfirmPassword
        )
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.$store.commit("SET_USERPASSWORD", this.Password.ConfirmPassword);
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        const user = await Auth.signIn(this.Login.EmailID, this.Login.Password);
        this.user = user;
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.$store.commit("SET_USERPASSWORD", this.Login.Password);

          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ActivateMethod() {
      this.$router.push("/LandingPage");
    },
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
  },
};
</script>
<style scoped>
.signInCard {
  display: grid;
  place-items: center;
  height: 80vh;
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
  --s: 65px; /* control the size */
  --c1: #dadee1;
  --c2: #4a99b4;
  --c3: #9cceb5;

  --_c: 75%, var(--c3) 52.72deg, #0000 0;
  --_g1: conic-gradient(from -116.36deg at 25% var(--_c));
  --_g2: conic-gradient(from 63.43deg at 75% var(--_c));
  background: var(--_g1), var(--_g1) calc(3 * var(--s)) calc(var(--s) / 2),
    var(--_g2), var(--_g2) calc(3 * var(--s)) calc(var(--s) / 2),
    conic-gradient(
      var(--c2) 63.43deg,
      var(--c1) 0 116.36deg,
      var(--c2) 0 180deg,
      var(--c1) 0 243.43deg,
      var(--c2) 0 296.15deg,
      var(--c1) 0
    );
  background-size: calc(2 * var(--s)) var(--s);
}
</style>
