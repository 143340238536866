<template>
  <v-card class="elevation-0 mx-6 mt-4">
    <v-card-text class="display-2"> Terms of Use </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >INTRODUCTION</v-card-title
    >
    <v-card-text class="font-weight-medium">
      These Terms of Use (hereafter referred to as TERMS) outline the rules and
      regulations for the use of the relievingletter.com Web Application
      (hereafter referred to as THE APP).
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >LICENSE</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >THE APP is a software product wholly owned by SureCreds Pvt. Ltd. Unless
      otherwise stated, SureCreds Pvt. Ltd. and/or its licensors own the
      intellectual property rights for all material on THE APP. All intellectual
      property rights are reserved.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >PERMISSIBLE USE</v-card-title
    >
    <v-card-text class="font-weight-medium">
      THE APP is licensed for paid usage by an organization and the authorized
      members thereof (hereafter together referred to as the USER). The USER
      account owner and designated members are responsible for providing
      accurate and complete information, and keeping the account information
      up-to-date. The following usage is PROHIBITED:
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4">
      • Republishing or redistributing material from THE APP
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4">
      • Selling, renting or sub-licensing material from THE APP
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4">
      • Reproducing, duplicating, or copying material from THE APP
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4">
      • Use for any illegal, harmful, or offensive purpose
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >COMMENCEMENT</v-card-title
    >
    <v-card-text class="font-weight-medium">
      An authorized representative of the USER would need to apply on the online
      link for opening an account on the APP. The agreement shall commence from
      the date of completing the registration of the USER with THE APP.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >HYPERLINKS</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >THE APP allows other websites and organisations to hyperlink to its home
      page provided that the link:
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• is not in any way deceptive
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• does not falsely imply sponsorship, endorsement or approval of the
      linking party and its products and/or services
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• fits within the context of the linking party's site
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• the link would not make THE APP look unfavorably to ourselves or to our
      accredited businesses
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• the USER does not have any negative records with us
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• there is benefit to us from the visibility of the hyperlink
    </v-card-text>
    <v-card-text class="font-weight-medium mt-n4"
      >• the link is in the context of general resource information.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >PRIVACY POLICY</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >Please refer to PRIVACY POLICY
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >CANCELLATION, RETURN AND REFUND</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >APP Credits can be purchased to top up the credit balance. Such purchases
      shall be final and no refund or cancelation will be made.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >DISCLAIMER</v-card-title
    >
    <v-card-text class="font-weight-medium">
      The APP software is provided "as is" and without warranties of any kind,
      whether express or implied, to the maximum extent permitted by applicable
      law. We do not guarantee that THE APP will be error-free or uninterrupted,
      or that any defects will be corrected.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >LIMITATION OF LIABILITY
    </v-card-title>
    <v-card-text class="font-weight-medium">
      To the fullest extent permitted by applicable law, we will not be liable
      for any indirect, incidental, special, consequential, or punitive damages
      arising out of or in connection with the USER's use of THE APP, including
      but not limited to lost profits, lost data, or business interruption.
      Nothing in this disclaimer will limit or exclude the statutory rights of
      either party.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >INDEMNIFICATION</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >The USER agrees to indemnify, defend, and hold harmless the vendor of the
      APP and its affiliates, officers, directors, employees, agents, and
      licensors from and against all claims, damages, liabilities, costs, and
      expenses (including reasonable legal fees) arising out of or in connection
      with their use of THE APP, their User Content, or their violation of these
      TERMS.</v-card-text
    >
    <v-card-title class="headline font-weight-bold grey--text"
      >TERMINATION</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >We reserve the right to terminate or suspend the access of the USER to
      THE APP at any time, for any reason, without notice or liability.
    </v-card-text>
    <v-card-title class="headline font-weight-bold grey--text"
      >GOVERNING LAW AND DISPUTE RESOLUTION</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >These TERMS and any dispute arising out of or in connection with them
      shall be governed by and construed in accordance with the laws of
      India.</v-card-text
    >
    <v-card-title class="headline font-weight-bold grey--text"
      >CHANGES</v-card-title
    >
    <v-card-text class="font-weight-medium"
      >We may update these TERMS from time to time, and any changes will be
      reflected in the updated version of the TERMS. Continued use of THE APP by
      the USER following the posting of any changes constitutes their acceptance
      of the revised TERMS.
    </v-card-text>
    <v-footer dark padless height="300px" class="mx-n7">
      <v-card
        flat
        tile
        class="white--text text-center elevation-0"
        color="transparent"
      >
        <v-card-text class="ml-10"
          >© 2023 SureCreds Pvt. Ltd. All rights reserved.</v-card-text
        >
      </v-card>
    </v-footer>
  </v-card>
</template>
<script>
export default {
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>
<style></style>
