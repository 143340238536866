<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      v-model="dialogVerificationRequests"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <div><span>Verify Credential</span></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogVerificationRequestsEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="8" align="center">
              <v-card outlined class="overflow-y-auto" height="100%">
                <v-card-title> Preview </v-card-title>
                <v-card-text v-if="StoreObj.type == 'TEMPLATE'">
                  <div v-html="StoreObj.template_html"></div>
                </v-card-text>
                <v-card-text v-if="StoreObj.type == 'FREE_TEXT'">
                  <div v-html="StoreObj.free_text"></div>
                </v-card-text>
                <v-card-text v-else>
                  <iframe
                    :height="heightPDF"
                    :src="StoreObj.s3_url"
                    width="100%"
                    frameborder="0"
                  ></iframe>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" fill-height>
              <v-card outlined height="100%" class="overflow-y-auto">
                <h3 class="ml-4 pt-5">Request Details :</h3>
                <h4 class="ml-4 pt-5">Request Details :</h4>
                <v-card-text>
                  <div>
                    <span class="font-weight-bold">Request Type :</span>
                    {{
                      StoreObj.type.includes("_") == true
                        ? StoreObj.type.replace("_", " ")
                        : StoreObj.type
                    }}
                  </div>
                  <div>
                    <span class="font-weight-bold">Requested On :</span>
                    {{ new Date(StoreObj.request_created_on).toLocaleString() }}
                  </div>
                </v-card-text>
                <h4 class="ml-4 pt-5">Requested By :</h4>
                <v-card-text>
                  <div>
                    <span class="font-weight-bold">Name :</span>
                    {{ StoreObj.user_name }}
                  </div>
                  <div>
                    <span class="font-weight-bold">Email ID :</span>
                    {{ StoreObj.user_email_id }}
                  </div>
                  <div>
                    <span class="font-weight-bold">Contact Number :</span>
                    {{
                      StoreObj.user_country_code +
                      "-" +
                      StoreObj.user_contact_number
                    }}
                  </div>
                  <v-form ref="form" v-if="action == 'REJECT_CREDENTIAL'">
                    <v-textarea
                      v-if="action == 'REJECT_CREDENTIAL'"
                      outlined
                      rows="1"
                      class="fontSize mt-4"
                      label="Comments"
                      v-model="reason"
                      :rules="
                        action == 'REJECT_CREDENTIAL'
                          ? [(v) => !!v || 'Password is required']
                          : ''
                      "
                    ></v-textarea>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius fontStyle text-capitalize"
            color="secondary"
            outlined
            @click="dialogVerificationRequestsEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            small
            dark
            class="borderRadius fontStyle text-capitalize"
            :loading="loadingREJECT_CREDENTIAL"
            color="red"
            @click="approveRejectValidateMethod('REJECT_CREDENTIAL')"
            ><v-icon small class="mr-1">mdi-cancel</v-icon>Reject</v-btn
          >
          <v-btn
            small
            dark
            class="borderRadius fontStyle text-capitalize"
            :loading="loadingAPPROVE_CREDENTIAL"
            color="green"
            @click="approveRejectValidateMethod('APPROVE_CREDENTIAL')"
            ><v-icon small class="mr-1">mdi-check</v-icon>Approve</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ApproveOrRejectVerificationRequest } from "@/graphql/mutations.js";
export default {
  props: {
    dialogVerificationRequests: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    heightPDF: 0,
    action: "",
    reason: "",
    SnackBarComponent: {},
    loadingREJECT_CREDENTIAL: false,
    loadingAPPROVE_CREDENTIAL: false,
  }),
  mounted() {
    this.heightPDF = window.innerHeight - 280;
  },
  methods: {
    async approveRejectMethod(action) {
      this[`loading${action}`] = true;
      let d = new Date();
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let date =
        d.getDate() +
        "-" +
        monthNames[d.getMonth()] +
        "-" +
        d.getFullYear() +
        "  " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      this.loading = true;
      var inputParams = {
        user_id: this.$store.getters.get_current_user_details.user_id,
        request_id: this.StoreObj.request_id,
        date: date,
        month: `${d.getFullYear()}-${d.getMonth() + 1}`,
        action: action,
      };
      if (action == "REJECT_CREDENTIAL") {
        inputParams.reason = this.reason;
      }
      try {
        let result = await API.graphql(
          graphqlOperation(ApproveOrRejectVerificationRequest, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(
          result.data.ApproveOrRejectVerificationRequest
        );
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogVerificationRequestsEmit((this.Toggle = 2), ResultObject);
          this[`loading${action}`] = false;
        }
      } catch (error) {
        console.warn("error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this[`loading${action}`] = false;
      }
    },
    approveRejectValidateMethod(action) {
      this.action = action;
      if (action == "REJECT_CREDENTIAL") {
        if (
          this.reason != undefined &&
          this.reason != null &&
          this.reason != ""
        ) {
          if (this.$refs.form.validate) {
            this.approveRejectMethod(action);
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Please Enter Comments",
          };
        }
      } else if (action == "APPROVE_CREDENTIAL") {
        this.approveRejectMethod(action);
      }
    },
    dialogVerificationRequestsEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
