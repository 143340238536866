<template>
  <div>
    <Overlay :overlay="overlay" />
    <!-- <VerifyCredentialsDialog
      :dialogVerificationRequests="dialogVerificationRequests"
      :StoreObj="StoreObj"
      @clicked="dialogVerificationRequestsEmit"
    /> -->
    <EmployeeAdditionalDetails
      :dialogAdditionalDetails="showadditionaldetails"
      @clicked="closeAdditionalDetailsEmit"
      :StoreObj="StoreObj"
    />
    <VerifyCredentialsMore
      :dialogverifyLetterMore="verifyLetterMore"
      @clicked="closeVerifyEmit"
    />
    <!--  -->

    <v-container fluid v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <v-data-iterator
        :items="listOfVerifiedLetters"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
        :search="search"
      >
        <template v-slot:header>
          <v-row align="center">
            <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
              <div style="text-align: center" class="ma-2">
                <h3>Verify Letter</h3>
              </div>

              <div style="text-align: center">
                <v-btn
                  dark
                  depressed
                  small
                  @click="verifyLetterMore = true"
                  color="green"
                  ><v-icon left small>mdi-eye</v-icon>Verify Letter</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </template>

        <template>
          <v-row>
            <v-col
              v-for="(item, index) in listOfVerifiedLetters"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="4"
            >
              <v-card elevation="1" outlined class="">
                <v-card-title class="subheading font-weight-bold">
                  <v-avatar color="green" class="mr-2" size="30">
                    <v-icon dark small> mdi-account-circle </v-icon>
                  </v-avatar>
                  {{ item.issued_to }}
                </v-card-title>

                <v-divider color="black"></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Verified On:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.date_verified }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Issued By:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.issued_by }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Designation:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.designation }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Worked From:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.worked_from }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Worked Till:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.worked_till }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Actions:</v-list-item-content>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="3" sm="1" xs="1">
                          <v-tooltip bottom v-if="item.status == 'Verified'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2 actionIcons"
                                @click="downloadItem(item)"
                              >
                                <v-icon color="green">mdi-download</v-icon>
                              </v-btn>
                            </template>
                            <span>Download</span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="3" sm="1" xs="1">
                          <v-tooltip bottom v-if="item.status == 'Verified'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                class="actionIcons mr-2"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="openViewBlock(item)"
                              >
                                <v-icon color="green">mdi-link-lock</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-center">View On Blockchain</span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="3" sm="1" xs="1">
                          <v-tooltip bottom v-if="item.status == 'Verified'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="actionIcons"
                                @click="showAdditionalDetailsMethod(item)"
                              >
                                <v-icon color="green"
                                  >mdi-account-details</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Details of Employee</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>

    <v-card
      class="ma-7"
      elevation="0"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
      flat
    >
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <div style="display: flex; flex-wrap: wrap">
            <div class="mt-1">
              <h2>Verify Letter:</h2>
            </div>
            <div class="ml-5">
              <div class="search">
                <input
                  type="text"
                  class="search__input"
                  placeholder="Search"
                  v-model="search"
                />
                <button class="search__button">
                  <svg
                    class="search__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="d-flex justify-end"
        >
          <div style="display: grid">
            <div class="d-flex justify-end">
              <v-btn
                style="position: relative; top: 5px"
                color="green"
                dark
                depressed
                @click="verifyLetterMore = true"
                ><v-icon left>mdi-eye</v-icon>Verify Letter</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- :height="windowTableheight" -->

    <v-card
      class="ma-7"
      dense
      outlined
      elevation="1"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
    >
      <v-data-table
        class=""
        dense
        :search="search"
        elevation="0"
        fixed-header
        :headers="GetAllVerificationRequestsListHeaders"
        :items="listOfVerifiedLetters"
        :footer-props="{
          'items-per-page-options': [100, 200, 300, 400, 500],
        }"
        :items-per-page="100"
        :no-data-text="noDataText"
      >
        <template v-slot:[`item.sl_no`]="{ item }">
          <div class="FontSize">
            {{ truncateText(item.sl_no) }}
          </div>
        </template>
        <template v-slot:[`item.date_verified`]="{ item }">
          <div class="FontSize">
            {{ getFormatedDate(item.date_verified) }}
          </div>
        </template>
        <template v-slot:[`item.issuer_email_id`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.issuer_email_id) }}
              </div>
            </template>
            <span>{{ item.issuer_email_id }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.issued_to`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.issued_to) }}
              </div>
            </template>
            <span>{{ item.issued_to }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.designation`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.designation) }}
              </div>
            </template>
            <span>{{ item.designation }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.department`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ item.department ? truncateText(item.department) : "--" }}
              </div>
            </template>
            <span> {{ item.department ? item.department : "--" }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.worked_from`]="{ item }">
          <div class="FontSize">
            {{ getFormatedDate(item.worked_from) }}
          </div>
        </template>
        <template v-slot:[`item.worked_till`]="{ item }">
          <div class="FontSize">
            {{ getFormatedDate(item.worked_till) }}
          </div>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="green"
                  class="mr-1"
                  @click="downloadItem(item)"
                  v-on="on"
                  >mdi-download</v-icon
                >
              </template>
              <span>Download</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="green" @click="openViewBlock(item)" v-on="on"
                  >mdi-link-lock</v-icon
                >
              </template>
              <span>View On Blockchain</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="green"
                  class="ml-1"
                  @click="showAdditionalDetailsMethod(item)"
                  v-on="on"
                  >mdi-account-details</v-icon
                >
              </template>
              <span>Show Additional Details</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import EmployeeAdditionalDetails from "@/components/Dashboard/Dialogs/EmployeeAdditionalDetails.vue"; //AdddItional details
import VerifyCredentialsDialog from "@/components/VerificationRequests/Dialogs/VerifyCredentialsDialog.vue";
import { GetAllVerificationRequests } from "@/mixins/GetAllVerificationRequests.js";
import VerifyCredentialsMore from "@/components/Dashboard/Dialogs/VerifyCredentialsMore.vue";
import { ListVeirifiedDocuments } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  mixins: [GetAllVerificationRequests],
  components: {
    VerifyCredentialsDialog,
    VerifyCredentialsMore,
    EmployeeAdditionalDetails,
    Overlay,
  },
  data: () => ({
    GetMenuOptions: [],
    listOfVerifiedLetters: [],
    windowTableheight: 0,
    itemsPerPage: 4,
    overlay: false,
    verifyLetterMore: false,
    showadditionaldetails: false,
    listOfVerifiedLetters: [],
    noDataText: "",
    StoreObj: {},
    search: null,
    dialogVerificationRequests: false,
    GetAllVerificationRequestsListHeaders: [
      { text: "Verified On", value: "date_verified" },
      // { text: "Issued By", value: "issued_by" },
      { text: "Issued By", value: "issuer_email_id" },

      { text: "Issued To", value: "issued_to" },
      { text: "Designation", value: "designation" },
      { text: "Department", value: "department" },
      { text: "Worked From", value: "worked_from" },
      { text: "Worked Till", value: "worked_till" },
      { text: "Actions", value: "Actions" },
      // { text: "Actions", value: "Actions" },
    ],
  }),
  async mounted() {
    this.windowTableheight = window.innerHeight - 190;
    this.$vuetify.goTo(0, 0);
    await this.GetVerifiedLetters();
    // console.log(
    //   "this.$store.getters.get_ListVeirifiedDocuments.issued_to",
    //   this.$store.getters.get_ListVeirifiedDocuments.issued_to
    // );
  },
  methods: {
    getFormatedDate(date) {
      let dateArray = date.split("-");

      return `${dateArray[0]} ${dateArray[1].substring(0, 3)} ${dateArray[2]}`;
    },
    GetMenuOptionMethod(item) {
      this.GetMenuOptions = [];
      this.GetMenuOptions.push(
        {
          MenuText: "Download",
          MenuIcon: "mdi-download",
          MenuValue: "DOWNLOAD",
          menuItem: item,
        },
        {
          MenuText: "View on Blockchain",
          MenuIcon: "mdi-link-box-variant",
          MenuValue: "VIEW_BLOCKCHAIN",
          menuItem: item,
        },
        {
          MenuText: "View Additional Details",
          MenuIcon: "mdi-account-details",
          MenuValue: "VIEW_ADDITIONAL_DETAILS",
          menuItem: item,
        }
      );
    },
    async CheckItem(item, action) {
      // console.log("item", item);
      switch (action) {
        case "DOWNLOAD":
          await this.downloadItem(item);

          // console.log("From Download");
          break;
        case "VIEW_BLOCKCHAIN":
          this.openViewBlock(item);
          // console.log("From View BlockChain");
          break;
        case "VIEW_ADDITIONAL_DETAILS":
          this.showAdditionalDetailsMethod(item);

          // console.log("From View Additional Details");
          break;
      }
    },

    toggleActionsMenu(item) {
      item.showActionsMenu = !item.showActionsMenu;
    },
    // dialogVerificationRequestsEmit(Toggle) {
    //   this.dialogVerificationRequests = false;
    //   if (Toggle == 2) {
    //     this.GetAllVerificationRequestsMethod();
    //   }
    // },
    // checkItem(item) {
    //   console.warn("Item", item);
    //   this.dialogVerificationRequests = true;
    //   this.StoreObj = item;
    // },
    showAdditionalDetailsMethod(item) {
      // console.log("Helo");
      this.showadditionaldetails = true;
      this.StoreObj = item.additional_details;
    },
    closeAdditionalDetailsEmit(val) {
      if (val == false) {
        this.showadditionaldetails = false;
      }
    },
    openViewBlock(item) {
      window.open(`https://viewblock.io/arweave/tx/${item.transaction_id}`);
    },
    truncateText(text) {
      const maxLength = 15; // Set your desired maximum length
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },

    async downloadItem(item) {
      this.overlay = true;
      const response = await fetch(item.URL);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.target = "_blank";
      anchor.download = `${item.issued_to}_RelievingLetter`;
      anchor.click();
      URL.revokeObjectURL(blobUrl);
      this.overlay = false;
    },
    // async downloadItem(item) {
    //   const response = await fetch(item.URL);
    //   // console.log(item.URL);
    //   const blob = await response.blob();
    //   const blobUrl = URL.createObjectURL(blob);
    //   const anchor = document.createElement("a");
    //   anchor.href = blobUrl;
    //   anchor.target = "_blank";
    //   // anchor.download = `RL_${new Date(item.transacted_on).getTime()}_${
    //   //   item.URL.split("_")[item.URL.split("_").length - 1]
    //   // }`;
    //   anchor.download = `RL_${
    //     item.URL.split("_")[item.URL.split("_").length - 1]
    //   }`;
    //   anchor.click();
    //   URL.revokeObjectURL(blobUrl);
    // },

    closeVerifyEmit(val) {
      if (val == false) {
        this.verifyLetterMore = false;
      }
      this.GetVerifiedLetters();
    },
    async GetVerifiedLetters() {
      try {
        this.overlay = true;

        let result = await API.graphql(
          graphqlOperation(ListVeirifiedDocuments, {
            input: {
              issuer_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        let resultObject = JSON.parse(result.data.ListVeirifiedDocuments);
        this.listOfVerifiedLetters = resultObject.data;
        // console.log("listOfVerifiedLetters", this.listOfVerifiedLetters);

        for (let i = 0; i < this.listOfVerifiedLetters.length; i++) {
          this.listOfVerifiedLetters[
            i
          ].URL = `https://workhistory-mumbai.s3.ap-south-1.amazonaws.com/${this.listOfVerifiedLetters[i].s3_key}`;
        }
        this.overlay = false;
      } catch (error) {
        // console.log("Error in ListVeirifiedDocuments", error);
        this.overlay = false;
        // console.log("Catch", this.overlay);
      }
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #f4f2f2;
  border: none;
  color: #646464;
  padding: 0.7rem 1rem;
  border-radius: 30px;
  width: 18em;
  transition: all ease-in-out 0.5s;
  margin-right: -2rem;
}

.search__input:hover,
.search__input:focus {
  box-shadow: 0 0 1em #00000013;
}

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  background-color: #f0eeee;
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  margin-top: 0.1em;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
}
</style>
